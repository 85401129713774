import styled from 'styled-components'
import { headerBackgroundColor, headerColor } from '../utils/colors'

const Takover = styled.div`
  width: 100vw;
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: -.5rem;
  padding: 0;
  background-color: ${headerBackgroundColor};
  color: ${headerColor};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Takover;
