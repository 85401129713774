/* eslint-disable no-unused-vars */

const gold = '#ccb285';
const lightGray = '#bbbbbb';
const purpleBlue = '#3a348f';

export const backgroundColor = 'white';
export const defaultColor = 'black';
export const primaryActionColor = 'royalblue'
export const neutralColor = lightGray;
export const higlightColor = gold;
export const headerBackgroundColor = purpleBlue;
export const headerColor = 'rgba(255, 255, 255, 0.8)';
