import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Takeover from '../components/Takeover'
import Text from '../components/Text'

const notFoundPage = ({className}) => (
  <Takeover className={className}>
    <h1>Ooops!</h1>
    <Text>HTTP 404 — Page not found.</Text>
    <Link to="/">Home</Link>
  </Takeover>
)

const NotFoundPage = styled(notFoundPage)`
font-family: Raleway, sans-serif;
  h1 {
    font-size: 4rem;
    margin: 0;
    transform: rotate(357deg);
  }
  p,
  a,
  a {
    display: block;
    font-size: 1.2rem;
    margin: 0;
    padding: .5rem;
    color: rgba(255, 255, 255, .8)
  }
  p {
    transform: rotate(1deg);
    margin-top: .75rem;
  }
  a {
    margin-top: 1rem;
    border: 1px solid transparent;
    &:visited {
      color: inherit;
    }
    &:focus,
    &:hover {
      border: 1px dotted white;
    }
  }
`

export default NotFoundPage
