import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const text = ({children, className}) => (
  <p className={className}>{children}</p>
)

text.propTypes = {
  children: PropTypes.node.isRequired,
}

export const Text = styled(text)`
  font-family: Raleway, sans-serif;
  font-size: 1rem;
`

export const SmallText = styled(Text)`
  font-family: Raleway, sans-serif;
  font-size: 0.675rem;
`

export const LargeText = styled(Text)`
  font-family: Raleway, sans-serif;
  font-size: 1.2rem;
`

export default Text;
